/* ==========================================================================

   DATA HIGHLIGHT

   ========================================================================== */

.data-highlight {
	@include breakpoint-desktop {
		font-size: px-to-rem($_big-font-size);
	}

	@include breakpoint-upscale {
		font-size: px-to-vw($_big-font-size);
	}
}

/* ==========================================================================

   LINK LIST (see also _components-content.scss)

   ========================================================================== */

ul.linklist {
	a:hover {
		@include menu-inverted {
			@include link-underline-important($black);
			opacity: $text-link-opacity !important;
		}
	}
}

/* ==========================================================================

   COLLAPSE ALL BUTTON

   ========================================================================== */

.collapse-all {
	@extend %neutralize-button;
	@extend %dark-link;

	&:before {
		content: attr(data-label-collapsed) " +";
	}

	&[aria-expanded="true"]:before {
		content: attr(data-label-expanded) " –";
	}
}

/* ==========================================================================

   COLLAPSE DROPDOWN

   ========================================================================== */

.collapse-dropdown {
	@extend %neutralize-button;
	@extend %dark-link;

	&:after {
		@extend %icon-arrow-down;
		content: "";
		display: inline-block;
		vertical-align: middle;
		transform: translate(0, em(-0.1));
		width: px-to-em($_collapse-dropdown-icon-w);
		height: px-to-em($_collapse-dropdown-icon-h);
		margin-left: $spacer * 0.25;
		@include transition($collapse-dropdown-icon-transition);
	}

	&[aria-expanded="true"] {
		&:after {
			transform: rotate(-180deg);
		}
	}
}

/* ==========================================================================

   SORTING

   ========================================================================== */

.akbild-sorting {
	@include list-unstyled;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row-reverse;

	@include breakpoint-desktop {
		margin-top: -($spacer + $lined-text-py);
	}

	&-item {
		margin-left: $spacer;
		white-space: nowrap;
	}

	@supports (row-gap: 1em) {
		row-gap: $spacer * 0.25;
	}

	&-item.selected &-link {
		&:before {
			@extend %icon-sorting-arrow;
			content: "";
			display: inline-block;
			width: px-to-em(12);
			height: px-to-em(11);
			margin-right: px-to-em(4.5);
		}
	}

	&-item.selected.desc &-link:before {
		transform: rotate(180deg);
	}

	&-link {
		@extend %dark-link;
	}
}

/* ==========================================================================

   LISTING

   ========================================================================== */

.akbild-listing {
	.list-group {
		@extend %list-group-content-styles;
	}

	&-header,
	&-footer {
		min-height: $spacer + $lined-text-py;
	}

	&-header {
		padding-bottom: $lined-text-py;
	}

	&-footer {
		padding-top: $lined-text-py;
	}

	.mosaic-grid-row & .col {
		flex: 1 0 0%; // NOTE: `!important` because of `mosaic-grid.css`
	}
}

/* ==========================================================================

   FILTER

   ========================================================================== */

.filter {
	&-search {
		position: relative;

		&-reset {
			// @extend %neutralize-button;
			display: none;
			position: absolute;
			top: 0;
			left: -$spacer * 0.75;
		}

		&-field:not(:placeholder-shown) + &-reset {
			display: block;
		}

		&-submit {
			display: block !important;
		}

		.mosaic-grid-row & .col {
			flex: 1 0 0%; // NOTE: `!important` because of `mosaic-grid.css`
		}
	}

	&-select {
		&:hover {
			background-color: $listing-hover-bg;

			@include color-inverted($nest: false) {
				background-color: $invert-listing-hover-bg;
			}

			option {
				@include dark-mode {
					color: $white;
				}
			}
		}
	}

	&-list-item {
		position: relative;

		// enlarge clickarea
		.list-group-link:after {
			content: "";
			background: transparent;
			position: absolute;
			top: 0;
			left: -$spacer * 1.25;
			right: 0;
			bottom: 0;
		}

		&.selected {
			&:before {
				content: "×";
				position: absolute;
				left: -$spacer * 0.75;
			}
		}

		// hide "all" option, that comes from plone output
		&.filter-all {
			display: none;

			&:first-child + .list-group-item {
				border-top: none;
			}
		}
	}
}
