@each $color-item in $su-colors {
	$unit: nth($color-item, 1);
	$bg-color: nth($color-item, 2);

	body#{getUnitSelector($unit)} {
		background-color: $bg-color;
		.mosaic-panel {
			.mosaic-selected-tile {
				background-color: $bg-color;
			}
		}
	}

	@include body-is-blank {
		#{getUnitSelector($unit)} {
			background-color: $bg-color;
		}
	}
}

.orgunit-bg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z-minus;
	display: flex;

	&-item {
		flex: 1 1 auto;
	}

	@each $color-item in $mu-colors {
		$unit: nth($color-item, 1);
		$bg-color: nth($color-item, 2);

		#{getUnitSelector($unit)} {
			background-color: $bg-color;
		}
	}
}
