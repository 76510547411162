// NOTE: Styles also used in tinymce-content.scss

/* ==========================================================================

   RE-REBOOT

   ========================================================================== */

ol:not([class]) {
	@include list-unstyled;
	counter-reset: counter;

	li {
		position: relative;
		padding-left: $spacer * 1.5;
		counter-increment: counter;

		&:before {
			content: counter(counter);
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

ul:not([class]) {
	@include list-unstyled;

	li {
		position: relative;
		padding-left: $spacer * 0.5;

		&:before {
			content: "·";
			position: absolute;
			top: 0;
			left: 0;
		}

		ul {
			padding-left: $spacer * 0.5;
		}
	}
}

/* ==========================================================================

   BOOTSTRAP OVERWRITES & EXTENSIONS

   ========================================================================== */

.table {
	margin-bottom: 0;
	overflow-wrap: normal;
	word-break: normal;
	hyphens: auto;

	> :not(:first-child) {
		border-top: $table-border-width solid $table-group-separator-color;
	}

	td,
	th {
		&:not(:last-child) {
			padding-right: $spacer;
		}
	}

	@include color-inverted($nest: false) {
		color: $white;
	}
}

.blockquote {
	font-style: italic;

	&,
	&-footer {
		padding-left: $spacer * 2;
	}

	&-footer {
		font-style: normal;

		&:before {
			content: "– ";
		}
	}
}

.figure-img {
	margin-bottom: 0;
}

/* ==========================================================================

   EDITOR IMAGES

   ========================================================================== */

img.image-richtext {
	@extend %display-max-width;
	display: block;
	height: auto;
	float: none !important;

	&.image-richtext-portrait {
		@extend %display-max-width-portrait;
	}
}

.image-richtext-figure {
	@extend %display-desktop-width;
	@extend %typo-small;

	&.image-richtext-portrait {
		@extend %display-desktop-width-portrait;
	}

	&,
	&.image-richtext-portrait {
		img.image-richtext {
			max-width: 100%;

			@include breakpoint-desktop {
				max-width: 100%;
			}

			@include breakpoint-upscale {
				max-width: 100%;
			}
		}
	}
}