html {
    @at-root body {
        word-spacing: $word-spacing-base;
        overflow-wrap: break-word; // break words if too long
        word-break: break-word;
        overflow: auto;
        overflow-x: hidden;

        @include breakpoint-upscale {
            font-size: px-to-vw($_copy-font-size);
        }

        @include page-wrapper($at-root: true) {
            padding-top: $spacer * 0.5;
            padding-bottom: $spacer * 0.5;

            @include print-only {
                padding: 0;
            }

            @at-root .skip-to-link {
                @extend %text-link;
                margin-left: px-to-rem($_grid-gutter-width);

                @include breakpoint-upscale {
                    margin-left: px-to-vw($_grid-gutter-width);
                }
            }

            @at-root .header,
                .footer {
                // Links without a class get a fallback link style
                a:not([class]),
                a[class=""] {
                    @extend %dark-link;
                }
            }

            @at-root .header {
                &-breadcrumbs {
                    display: flex;
                    padding-right: $spacer * 1.3;

                    @include print-only {
                        display: block;
                    }

                    @at-root .logo {
                        @at-root a.logo {
                            @extend %link-unstyled;
                        }

                        &-a {
                            white-space: nowrap;

                            &:after {
                                content: "...";
                            }
                        }

                        &:hover,
                        &:focus {
                            + #portal-breadcrumbs {
                                @include visually-hidden();
                            }
                        }

                        @include not-startpage {
                            margin-right: $spacer * 0.25;

                            @include screen-only {
                                &:not(:hover):not(:focus):not(.logo-open) .logo-rest {
                                    @include visually-hidden();
                                }
                            }
                        }

                        @include startpage {
                            &:not(.logo-small) {
                                font-size: px-to-rem($_logo-font-size-mobile);
                                line-height: $logo-line-height;
                                letter-spacing: $logo-letter-spacing;

                                @include breakpoint-desktop {
                                    font-size: px-to-rem($_logo-font-size);
                                    padding-right: em(1);
                                }

                                @include breakpoint-upscale {
                                    font-size: px-to-vw($_logo-font-size);
                                }
                            }
                        }
                    }

                    @at-root #portal-breadcrumbs {
                        @include startpage {
                            display: none;
                        }
                    }
                }

                &-nav {
                    @include print-only {
                        display: none;
                    }

                    .nav-link[data-bs-toggle="offcanvas"] {
                        @extend %ios-defocus-fix;
                    }

                    @at-root .languageselector {
                        @include list-unstyled;

                        .currentLanguage {
                            display: none;
                        }

                        a {
                            @extend %nav-link;
                        }
                    }

                    @at-root .nav-global {
                        border-bottom: px-to-rem($_border-width) solid;

                        > .nav-item {
                            width: 100%;

                            @include breakpoint-menu2col {
                                > .row {
                                    > .nav-global-col-left {
                                        @include make-col(2, 6);
                                    }

                                    > .nav-global-col-right {
                                        @include make-col(4, 6);

                                        > .nav {
                                            padding-left: 0;

                                            > .nav-item:first-child {
                                                padding-top: 0;
                                                border-top: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .nav {
                            padding-left: $spacer * 1.25;
                            flex-direction: column;

                            &-item {
                                @extend %top-lined-text;
                                margin-right: 0 !important;

                                &:not(.has_subtree) {
                                    padding-bottom: $lined-text-py;
                                }

                                &.current {
                                    @extend %dot-marker;
                                }
                            }

                            &-link {
                                @include remove-link-underline;

                                &[type="button"] {
                                    margin-bottom: $lined-text-py;
                                }
                            }
                        }
                    }
                }
            }

            @at-root .search-overlay {
                padding-top: $content-wrapper-pt;
                padding-bottom: $content-wrapper-pb;
                display: none;

                &.--open {
                    display: block;
                }

                &.--open ~ .content-wrapper {
                    visibility: hidden;
                }

                &-form {
                    margin-top: $spacer;
                    border-top: $border-width solid;
                    border-bottom: $border-width solid;
                }

                @at-root a[data-akbild-s-trigger][aria-expanded="true"] {
                    @extend %dot-marker;
                }
            }

            @at-root .content-wrapper {
                padding-top: $content-wrapper-pt;
                padding-bottom: $content-wrapper-pb;
                min-height: 85vh;

                @include print-only {
                    padding-bottom: 0;
                    min-height: 0;
                }
            }

            @at-root .footer {
                @extend %typo-small;

                @at-root .jump-to-top {
                    @extend %nav-link;
                    position: absolute;
                    top: px-to-em($_small-font-size * $copy-line-height * -2, $font-size: $_small-font-size);
                }
            }
        }
    }
}
