@include print-only {
	@page {
		size: a4;
		width: map-get($grid-breakpoints, md);
		margin: 1.5cm 2cm;
	}

	*,
	*::before,
	*::after {
		// Bootstrap specific; comment out `color` and `background`
		color: $black !important; // Black prints faster
		text-shadow: none !important;
		background: transparent !important;
		box-shadow: none !important;
	}

	abbr[title]::after {
		content: " (" attr(title) ")";
	}

	// @include content-wrapper {
	// 	a[href^="http"]::after {
	// 		content: " (" attr(href) ")";
	// 		font-size: $small-font-size;
	// 	}
	// }

	pre {
		white-space: pre-wrap !important;
	}
	pre,
	blockquote {
		page-break-inside: avoid;
	}

	// Printing Tables:
	// https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables

	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	video,
	iframe,
	img {
		display: none;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	// Bootstrap components
	.navbar {
		display: none;
	}
	.badge {
		border: $border-width solid $black;
	}

	.table {
		border-collapse: collapse !important;

		td,
		th {
			background-color: $white !important;
		}
	}

	.table-bordered {
		th,
		td {
			border: 1px solid $gray-300 !important;
		}
	}

	.table-dark {
		color: inherit;

		th,
		td,
		thead th,
		tbody + tbody {
			border-color: $table-border-color;
		}
	}

	.video {
		display: none;
	}

	// Plone print styles replacement

	#content-header,
	#mainnavigation-wrapper,
	// #portal-breadcrumbs,
	#portal-footer-wrapper,
	#portal-personaltools,
	// #portal-searchbox,
	#edit-zone {
		display: none;
		visibility: hidden;
	}
}
