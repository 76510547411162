.rich-text,
.mce-content-body,
body#tinymce {
	@include not-in-listing-or-filter-tile {
		@extend %rich-text-base;
	}

	#{$select-mosaic-first-node-in-area},
	#{$select-mosaic-first-node-in-area} .migrated_related_text,
	#{$select-mosaic-first-node-in-area} .migrated_related_text div {
		@extend %rich-text-remove-first-margin;
	}

	@extend %rich-text-base-extended;
	@extend %rich-text-mosaic-tiles;

	// event detail
	.data-highlight {
		+ h1,
		+ h2 {
			margin-top: $spacer;
		}
	}

}

.rich-text-main {
	@include not-in-listing-or-filter-tile {
		@extend %rich-text-main-column;
	}
}

.rich-text-small {
	@extend %rich-text-small-column;
}

.rich-text,
div.mce-content-body[contenteditable="true"],
body#tinymce {
	&.area-above,
	&.area-below {
		img.image-richtext {
			max-width: $sidebar-logo-w !important;

			&:not(:hover) {
				filter: grayscale(1);
				mix-blend-mode: multiply;

				@include color-inverted {
					filter: grayscale(1) invert(1);
					mix-blend-mode: screen;
				}
			}
		}
	}
}
div.mce-content-body[contenteditable="true"],
body#tinymce {
	border: 1px solid var(--bs-gray-300);
	border-radius: 0;
	@extend %rich-text-base;
    @extend %rich-text-remove-first-margin;
	@extend %rich-text-base-extended;
	@extend %rich-text-mosaic-tiles;
}

// fix issue https://git.akbild.ac.at/akbild-webportal/dokumentation_tests/-/issues/794
// Tool Bar bewegt sich nicht mit
div.mosaic-tile-content.mce-content-body.mce-edit-focus {
	max-height: 75vh;
	overflow-y: scroll;
}