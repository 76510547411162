// NOTE: Styles also used in tinymce-content.scss

/* ==========================================================================

   DEFINITION LIST GROUP

   ========================================================================== */

.dl-group {
	dl {
		display: flex;
		flex-wrap: wrap;

		dt {
			// NOTE: width is calculated by js
			flex: 0 0 auto;
			max-width: 100%;
		}

		dd {
			flex: 1 0 0%;
			min-width: px-to-rem(320 - $_grid-gutter-width * 2 - $_copy-font-size * $copy-line-height);
			padding-left: $spacer;
		}
	}
}

/* ==========================================================================

   LINK LIST (see also _components.scss)

   ========================================================================== */

ul.linklist {
	@include list-unstyled;

	li {
		position: relative;
		padding-left: $spacer * 0.666;

		&:before {
			content: ">";
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	a {
		@extend %link-unstyled-important;
		display: inline !important;
		opacity: 1 !important;
		@include remove-link-underline-important;

		&:hover {
			@include link-underline-important($black);
			opacity: $text-link-opacity !important;

			@include color-inverted {
				@include link-underline-important($white);
				opacity: $invert-text-link-opacity !important;
			}
		}
	}

	ul {
		@include list-unstyled;
	}
}
