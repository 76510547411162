@function getUnitSelector($unit, $main: false) {
	@return ".orgunit-#{$unit}";
}

@function getBlankBodySelector($is-child: false) {
	$white-selectors: ();

	@if $is-child {
		$white-selectors: ('body:not([class*="orgunit-"]) &');
	} @else {
		$white-selectors: ('body:not([class*="orgunit-"])');
	}

	@each $su-color in $su-colors {
		$unit: nth($su-color, 1);
		$bg-color: nth($su-color, 2);

		// contrast of 21:1 means black & white
		@if contrast-ratio($bg-color, $black) == 21 {
			@if $is-child {
				$white-selectors: join($white-selectors, "body#{getUnitSelector($unit)} &", comma);
			} @else {
				$white-selectors: join($white-selectors, "body#{getUnitSelector($unit)}", comma);
			}
		}
	}

	@return $white-selectors;
}

@mixin body-is-blank {
	$selectors: ();

	@if & {
		$selectors: getBlankBodySelector(
			$is-child: true,
		);
	} @else {
		$selectors: getBlankBodySelector();
	}

	@media only screen {
		#{ $selectors } {
			@content;
		}
	}
}

@mixin color-inverted($same-selector: false, $is-child-selector: false, $only-screen: true, $nest: true) {
	$inverted-selectors: ();
	$nested-inverted-selectors: ();

	@each $su-color in $su-colors {
		$unit: nth($su-color, 1);
		$bg-color: nth($su-color, 2);

		@if contrast-ratio($bg-color, $body-color) < $min-contrast-ratio {
			@if $same-selector {
				$inverted-selectors: join($inverted-selectors, "&#{getUnitSelector($unit)}", comma);
			} @else if & and not $is-child-selector {
				$inverted-selectors: join($inverted-selectors, "body#{getUnitSelector($unit)} &", comma);
				$nested-inverted-selectors: join($nested-inverted-selectors, "#{getUnitSelector($unit)} &", comma);
			} @else {
				$inverted-selectors: join($inverted-selectors, "body#{getUnitSelector($unit)}", comma);
				$nested-inverted-selectors: join($nested-inverted-selectors, "#{getUnitSelector($unit)}", comma);
			}
		}
	}

	@if length($nested-inverted-selectors) > 0 {
		@if $nest and $only-screen and not $same-selector and not $is-child-selector {
			#{$nested-inverted-selectors } {
				@include body-is-blank {
					@content;
				}
			}
		}
	}

	@if length($inverted-selectors) > 0 {
		@if $only-screen {
			@media only screen {
				#{ $inverted-selectors } {
					@content;
				}
			}
		} @else {
			#{ $inverted-selectors } {
				@content;
			}
		}
	}
}

@mixin bg-single-unit {
	@each $color-item in $su-colors {
		$unit: nth($color-item, 1);
		$bg-color: nth($color-item, 2);

		#{getUnitSelector($unit)} & {
			background-color: $bg-color;
		}
	}
}

@mixin bg-multiple-unit {
	@each $color-item in $mu-colors {
		$unit: nth($color-item, 1);
		$bg-color: nth($color-item, 2);

		#{getUnitSelector($unit)} & {
			background-color: $bg-color;
		}
	}
}

@mixin is-multiple-unit($same-selector: false) {
	$selectors: ();

	@each $mu-color in $mu-colors {
		$unit: nth($mu-color, 1);
		@if $same-selector {
			$selectors: join($selectors, "&#{getUnitSelector($unit)}", comma);
		} @else {
			$selectors: join($selectors, "body#{getUnitSelector($unit)} &", comma);
		}
	}

	@if length($selectors) > 0 {
		@media only screen {
			#{ $selectors } {
				@content;
			}
		}
	}
}
