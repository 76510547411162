/* ==========================================================================

   CUSTOM REBOOT

   ========================================================================== */

// macOS font smoothing
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

label {
	cursor: pointer;
	user-select: none;
}

table {
	border-spacing: 0;
	&.plain {
		thead,
		tbody,
		tfoot,
		tr,
		td,
		th {
			border-style: none;
		}
	}
}

button,
input,
select,
textarea,
ins {
	color: inherit;
	letter-spacing: inherit;
}

// Hide arrows in input number field
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* clears the 'X' from Edge */
input::-ms-clear,
input::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

input::placeholder {
	white-space: nowrap;
}

input[type="range"] {
	&::-webkit-slider-runnable-track,
	&::-moz-range-track {
		cursor: pointer;
	}

	&::-webkit-slider-thumb,
	&::-moz-range-thumb {
		cursor: pointer;
	}
}

dl,
ol,
ul,
dd,
pre,
address,
figure,
blockquote {
	margin: 0;
	padding: 0;
}

iframe,
svg {
	vertical-align: middle;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

blockquote,
q {
	quotes: none;
}

abbr[title],
abbr[data-original-title] {
	text-decoration: none;
}

::selection {
	color: $selection-color;
	background: $selection-background-color;
	text-shadow: none;
}
::-moz-selection {
	color: $selection-color;
	background: $selection-background-color;
	text-shadow: none;
}

*:invalid {
	box-shadow: none;
}

mark {
	color: inherit;
}

ol,
ul {
	list-style-position: inside;
}

del {
	text-decoration: line-through;
}

u {
	text-decoration: none;
}

th {
	font-weight: normal;
}

h1 {
	margin-bottom: 0;
}
