/* ==========================================================================

   RE-REBOOT

   ========================================================================== */

@include page-wrapper {

	b,
	strong {
		@extend %typo-strong;
		@extend %typo-strong-print;
	}

	u,
	ins {
		text-decoration: none;
		font-style: italic;
	}

	.collectionSortOn {
		background: transparent;
	}
}

/* ==========================================================================
   FOCUS VISIBLE (https://github.com/WICG/focus-visible)
   ========================================================================== */

.js-focus-visible {

	*:focus,
	a:hover:focus,
	a:active:focus,
	button:-moz-focusring,
	[type="button"]:-moz-focusring,
	[type="reset"]:-moz-focusring,
	[type="submit"]:-moz-focusring {
		&:not(.focus-visible) {
			outline: none;
			color: inherit;
		}
	}

	*:focus.focus-visible {
		outline: px-to-rem($_outline-width) solid $black;
		outline-offset: 0;
		opacity: 1;
	}

	a,
	button {
		&.focus-visible:focus {
			background-color: $black !important;
			color: $white !important;
			opacity: 1 !important;
		}
	}

	// must be direct child of html-class
	@include color-inverted($is-child-selector: true) {
		*.focus-visible:focus {
			outline: px-to-rem($_outline-width) solid $white;
			outline-offset: 0;
			opacity: 1;
		}

		a,
		button {
			&.focus-visible:focus {
				background-color: $white !important;
				color: $black !important;
				opacity: 1;
			}
		}

		#{$select-menu} {
			*.focus-visible:focus {
				outline: px-to-rem($_outline-width) solid $black;
			}

			a,
			button {
				&.focus-visible:focus {
					background-color: $black !important;
					color: $white !important;
				}
			}
		}
	}
}

/* ==========================================================================
   INVERTED COLOR
   ========================================================================== */

@include color-inverted {
	color: $white;

	::selection,
	::-moz-selection,
	.select2-result {
		color: $invert-selection-color;
		background: $invert-selection-background-color;
	}

	mark {
		background-color: $invert-mark-bg;
	}

	.invalid-feedback {
		color: $invert-red;
	}

	#{$select-plone-modal} {
		color: $black;
	}

}

/* ==========================================================================

   BOOTSTRAP OVERWRITES & EXTENSIONS

   ========================================================================== */

.breadcrumb {
	&-item {
		&:not(:last-child) {
			padding-right: $breadcrumb-item-padding-x;
		}

		@include color-inverted($nest: false) {
			+.breadcrumb-item::before {
				color: $invert-breadcrumb-divider-color;
			}

			&.active {
				color: $invert-breadcrumb-active-color;
			}
		}
	}

	&-item+&-item {
		padding-left: 0;
	}
}

.pagination {
	flex-wrap: wrap;

	@at-root .page {
		&-item {
			&:not(:last-child) {
				margin-right: $spacer * 0.333;
			}

			&.disabled {
				display: none;
			}
		}

		&-item.active &-link {
			@extend %text-link;
		}

		&-item:not(.active) {
			.page-link {
				@extend %dark-link;
			}
		}

		&-custom-link {
			&-prev:before {
				content: "<";
				padding-right: $space-px;
			}

			&-next:after {
				content: ">";
				padding-left: $space-px;
			}
		}
	}
}

.accordion {
	&:not(.accordion-flush) {
		border-bottom: $border-width solid $black;
	}

	&-item {

		&,
		&:not(:first-of-type) {
			border-top: $border-width solid $black;
		}
	}

	@include color-inverted($nest: false) {

		&,
		&-item {
			border-color: $white;
		}
	}

	&-button {
		font-size: inherit;

		&:after {
			@extend %icon-arrow-down;
			width: px-to-em($_collapse-dropdown-icon-w);
			height: px-to-em($_collapse-dropdown-icon-h);
			margin-left: $spacer * 0.5;
		}

		&:not(.collapsed) {
			&:after {
				@extend %icon-arrow-down;
				transform: $accordion-icon-transform;
			}
		}

		&:hover {
			background: $listing-hover-bg;

			@include color-inverted($nest: false) {
				background: $invert-listing-hover-bg;
			}
		}
	}

	&-body {
		border-top: $border-width solid;
	}

	&-collapse {
		padding-left: $spacer * 1.25;
	}

	// Nested
	&-item & {
		border-bottom: none;
	}
}

.list-group {
	&:not(.list-group-flush) {
		border-bottom: $border-width solid $black;
	}

	&-item+&-item {
		border-top-width: $border-width;
	}

	&-item {
		border-top: $border-width solid $black;
		position: relative;

		.orgunit-bg {
			display: none;
		}

		@include body-is-blank {
			.orgunit-bg {
				position: absolute;
				display: flex;
			}

			@include color-inverted($same-selector: true, $nest: false) {
				border-top-color: $white;
			}
		}

		&-action {
			cursor: pointer;

			@media (hover: hover) {

				&:focus-within,
				&:hover {
					background: $listing-hover-bg;

					@include color-inverted {
						background: $invert-listing-hover-bg;
					}

					@include body-is-blank {
						background: $listing-hover-bg;

						@include color-inverted($same-selector: true, $nest: false) {
							border-top-color: $black;
							background: $listing-hover-bg;
							color: $black;

							a:not(.list-group-link):hover {
								opacity: $text-link-opacity;
								@include link-underline($black);
							}
						}

						.orgunit-bg {
							display: none;
						}
					}
				}
			}
		}

		a.list-group-link {
			@extend %link-unstyled;
		}

		a:not(.list-group-link) {
			@extend %link-unstyled;
			@extend %nav-link;
		}

		.content-layout-home .mosaic-tile-list-big .listing_columns & {
			@extend %typo-big;
			padding: px-to-em(8, $_big-font-size) 0;
		}
	}

	@include color-inverted {

		&,
		&-item {
			border-color: $white;
		}
	}

	&-small {
		@extend %typo-list-small;
	}

	&-image {
		&-wrap {
			position: relative;
			width: 100%;
			height: 100%;

			&:before {
				content: "";
				display: block;
				padding-top: map-get($aspect-ratios, "3x2");
			}
		}

		& {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100% !important; // NOTE: `!important` because of `mosaic-styles.css`
			object-fit: contain;
			object-position: right top;
		}
	}

	// applies if body has multiple-unit-class
	@include is-multiple-unit() {
		&-item:not(:hover):not(:focus-within) {
			.list-group-image {
				@include gray-multiply;
			}
		}
	}

	// TODO: [Phase 2] Multiply funktioniert nicht überall (siehe test-sheet-table). Könnte man aber machen, indem man dem bild eine Hintergrundfarbe gibt. Allerdings bräuchte man dazu eine neue Klasse `orgunit-last-ibk` für multiple units im `body` und in den `list-group-items`
	// &-item:not(:hover):not(:focus-within) &-image-wrap {
	// 	@include bg-multiple-unit;
	// }

	// applies if body is blank and .list-item has multiple-unit-class
	@include body-is-blank {
		&-item:not(:hover):not(:focus-within) {
			@include is-multiple-unit($same-selector: true) {
				.list-group-image {
					@include gray-multiply;
				}
			}
		}
	}
}

.carousel {
	width: 100%;

	@include print-only {
		page-break-inside: always;
		display: none;
	}

	&-item {

		// make 3:2 ratio, if not enlarged
		&:before {
			content: "";
			display: block;
			padding-top: map-get($aspect-ratios, "3x2");
		}

		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100% !important; // NOTE: `!important` because of `mosaic-styles.css`
			object-fit: scale-down;
			object-position: left bottom;
		}
	}

	&-custom {
		&-wrap {

			// added via js
			.orgunit-bg {
				display: none;
			}
		}

		&-link {
			.js-focus-visible &:focus.focus-visible {
				img {
					outline: px-to-rem($_outline-width) solid $black;
					outline-offset: px-to-rem(-$_outline-width);
					opacity: 1;
				}
			}
		}

		&-footer {
			@extend %typo-small;
			display: flex;
			padding-top: $carousel-custom-footer-pt;
		}

		&-controls,
		&-display,
		&-zoom {
			flex: 0 0 auto;
		}

		&-zoom {
			&-btn {
				@extend %neutralize-button;
				@extend %dark-link;
				@extend %enlarge-clickarea;
				@extend %ios-defocus-fix;
			}

			&-in {
				&:after {
					content: "+";
				}
			}

			&-out {
				&:after {
					content: "×";
				}
			}
		}

		&-zoom+&-controls {
			margin-left: $carousel-custom-controls-pr;
		}

		&-controls {
			white-space: nowrap;

			&-btn {
				@extend %neutralize-button;
				@extend %dark-link;
				@extend %enlarge-clickarea;

				// enlarged clickarea
				&:last-child:before {
					left: em(-0.125);
				}
			}
		}

		&-controls+&-display {
			margin-left: $carousel-custom-controls-pr;
		}

		&-current-caption {
			flex: 1;

			&:not(:first-child) {
				margin-left: $carousel-custom-caption-pl;
			}
		}

		// fake click for startpage slider
		&-listing &-current-caption {
			cursor: pointer;
		}

		&-intuitive-controls {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			pointer-events: none;

			&-prev,
			&-zoom,
			&-next {
				flex: 1;
				pointer-events: auto;
			}

			&-prev,
			&-prev+&-zoom,
			&-next {
				max-width: percentage(div(1, 3));
			}

			// TODO: [Phase 2] Carousel cursor als svg https://t.ly/EYWwy – sonst auf windows pfeil links-rechts (siehe test-sheet-table)

			&-zoom {
				cursor: zoom-in;

				.carousel-custom-fullscreen & {
					cursor: zoom-out;
				}
			}

			&-prev {
				cursor: pointer;
				cursor: w-resize;
			}

			&-next {
				cursor: pointer;
				cursor: e-resize;
			}
		}
	}

	&-custom-fullscreen {
		&.carousel-custom-wrap {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: $zindex-modal;
			display: flex;
			flex-direction: column;
			padding: $spacer $grid-gutter-width;
			// Fallback color
			background: $white;
			@include bg-single-unit;

			// added via js
			.orgunit-bg {
				display: flex;
				position: absolute;
			}

			@include breakpoint-desktop {
				padding: $spacer calc(#{div(100%, $grid-columns)} - #{px-to-rem(
 div($_grid-gutter-width, $grid-columns) - $_grid-gutter-width)
			});
		}

		@include breakpoint-upscale {
			padding: $spacer calc(#{div(100%, $grid-columns)} - #{px-to-vw(
 div($_grid-gutter-width, $grid-columns) - $_grid-gutter-width)
		});
	}
}

.carousel {
	&-inner {
		flex: 1;
	}

	&-item {
		height: 100%;

		// disable ratio
		&:before {
			content: none;
		}

		img {
			object-position: left center;
		}
	}

	&-custom {
		&-footer {
			align-items: flex-end;
		}
	}
}
}
}

.offcanvas {
	@include scrollable;
	padding-top: $spacer * 0.5;
	color: $black;

	::selection {
		color: $selection-color !important;
		background: $selection-background-color !important;
	}

	::-moz-selection {
		color: $selection-color !important;
		background: $selection-background-color !important;
	}

	.js-focus-visible &:focus {
		&.focus-visible {
			outline: 0 !important;
		}

		&:not(.focus-visible) {
			color: $black;
		}
	}

	&-end {
		@include breakpoint-desktop {
			--bs-offcanvas-width: calc(#{percentage(div(9, 12))} + #{px-to-rem(($_grid-gutter-width * div(9, 12)) - $_grid-gutter-width-half)});
			width: var(--bs-offcanvas-width);
		}

		@include media-breakpoint-up(lg) {
			--bs-offcanvas-width: calc(50% + #{px-to-rem($_grid-gutter-width-half)});
			width: var(--bs-offcanvas-width);
		}

		@include breakpoint-upscale {
			--bs-offcanvas-width: calc(50% + #{px-to-vw($_grid-gutter-width-half)});
			width: var(--bs-offcanvas-width);
		}
	}

	&-header {
		align-items: flex-start;
		margin-bottom: $spacer;

		@include breakpoint-desktop {
			margin-bottom: $spacer * 2.5;
		}

		.btn-close {
			margin-top: $spacer * 0.25;
		}
	}

	&-body {
		overflow-y: visible;
	}

	&-custom {
		&-footer {
			margin-top: $spacer * 2;
			padding-bottom: $spacer * 0.5;

			@include breakpoint-desktop {
				margin-top: $spacer * 3.5;
			}
		}
	}
}

.btn-close {
	@extend %icon-close;

	&:hover:active {
		opacity: $text-link-opacity;
	}

	@include color-inverted($nest: false) {

		&:hover,
		&:hover:active {
			opacity: $invert-text-link-opacity;
		}
	}

	@include menu-inverted {

		&:hover,
		&:hover:active {
			opacity: $text-link-opacity;
		}
	}
}

.nav {

	&:not(.flex-column):not(.nav-tabs):not(.nav-pills) &-item,
	&:not(.flex-column):not(.nav-tabs):not(.nav-pills) &-link {
		margin-right: $spacer;

		&:last-child {
			margin-right: 0;
		}
	}

	&-link {
		@extend %nav-link;

		&:focus {
			color: inherit;
		}
	}

	&.nav-tabs,
	&.nav-pills {
		.nav-link {
			padding: $lined-text-py $spacer * 0.5;
			display: block;

			&:hover {
				@include remove-link-underline;
			}
		}
	}

	@at-root button.nav-link {
		@extend %neutralize-button;
		display: inline;
	}
}

#portal-globalnav-wrapper,
#portal-footer-wrapper {
	ul.nav {
		--bs-nav-link-padding-x: 0;
		--bs-nav-link-padding-y: 0;
	}
}


.display-1 {
	@extend %typo-pagetitle;
}

.dropdown {
	&-menu li {
		&:not(:first-child) {
			border-top: 1px solid;
		}
	}

	@include page-wrapper {

		&-item,
		&-dark &-item {

			&.active,
			&:active {
				background: $theme-primary-active;
			}
		}
	}
}

.btn {
	font-size: inherit;

	&-secondary {
		.js-focus-visible &:focus:not(.focus-visible) {
			color: color-contrast($secondary);
		}
	}

	&-dark {
		@include button-variant($background: $dark,
			$border: $dark,
			$hover-background: $theme-primary-active,
			$hover-border: $theme-primary-active,
			$active-background: $theme-primary-active,
			$active-border: $theme-primary-active);
	}

	&-link {
		text-decoration: none;

		&:hover {
			opacity: $text-link-opacity;
		}
	}

	&-custom {
		@extend %custom-btn;
	}
}

.form {
	&-control {
		font-size: inherit;

		@include color-inverted($nest: false) {
			color: $white;
			border-color: inherit;

			&:focus {
				background: $black;
			}

			&.is-invalid,
			.was-validated &:invalid {
				border-color: $invert-red;
			}
		}

		@include plone-modal-inverted {
			color: $black;
			border-color: $black;
			background: $white;

			&:focus {
				background: $white;
			}
		}

		&-custom {
			@extend %custom-form-control;
			border: none;

			&::placeholder {
				opacity: $text-link-opacity;

				@include color-inverted($nest: false) {
					opacity: $invert-text-link-opacity;
				}
			}
		}
	}

	&-select {
		cursor: pointer;
		font-size: inherit;
		color: inherit;
		-webkit-appearance: none;

		&:not([multiple]) {
			@extend %icon-select-indicator;
		}

		@include color-inverted($nest: false) {
			color: $white;
			border-color: inherit;

			option {
				color: $black;
			}

			&[multiple] option {
				color: $white;
			}
		}

		@include plone-modal-inverted {
			color: $black;
			border-color: inherit;

			&[multiple] option {
				color: $black;
			}
		}

		&-custom {
			border: none;
			@extend %custom-form-select;
		}
	}

	&-range {
		.js-focus-visible &:focus.focus-visible {
			outline: 0;

			&::-webkit-slider-runnable-track,
			&::-moz-range-track {
				height: px-to-em(3);
			}

			&::-webkit-slider-thumb,
			&::-moz-range-thumb {
				border: none;
				outline: 0;
			}
		}
	}

	&-check {
		&-input {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			-webkit-print-color-adjust: exact;

			&[type="checkbox"] {
				border-radius: 0;
			}

			@include color-inverted($nest: false) {
				border-color: $white;
			}

			@include plone-modal-inverted {
				border-color: $black;
			}
		}

		&-custom &-input {
			@extend %custom-form-check;
		}
	}

	&-custom {
		border-bottom: $border-width solid;

		&-row {
			border-top: $border-width solid;
		}

		.col-form-label {
			padding-top: $lined-text-py;
			padding-bottom: $lined-text-py;
		}

		&-check-group {
			padding-top: $lined-text-py;
			padding-bottom: $lined-text-py;
		}

		&-submit {
			@extend %custom-form-submit;
		}

		// NOTE: `!important` because of `mosaic-grid.css`
		.mosaic-grid-row & {
			.col {
				flex: 1 0 0%;
			}

			.col-sm-3 {
				@include media-breakpoint-up(sm) {
					width: percentage(div(3, 12));
				}
			}

			.col-lg-2 {
				@include media-breakpoint-up(lg) {
					width: percentage(div(2, 12));
				}
			}
		}
	}

	&-text {
		@include color-inverted {
			color: $white;
		}

		@include plone-modal-inverted {
			color: $black;
		}
	}
}

.alert {
	border-width: $alert-border-width !important;
	border-radius: $alert-border-radius;
	margin-bottom: $alert-margin-bottom;

	a {
		font-weight: $alert-link-font-weight;
	}

	&-dismissible .btn-close {
		padding: 0;
		top: 50%;
		right: $spacer * 0.5;
		transform: translate(0, -50%);
	}
}

.modal {
	&-header .btn-close {
		padding: 0;
		margin: 0;
	}
}

@include page-wrapper {
	.bg-primary {
		background-color: $theme-primary !important;
		color: $white;
	}

	.text-primary {
		color: $theme-primary !important;
	}

	.border-primary {
		border-color: $theme-primary !important;
	}

	.nav.nav-pills .nav-link.active {
		background-color: $theme-primary;
	}

	.btn {
		&-primary {
			@include button-variant($background: $theme-primary,
				$border: $theme-primary,
				$hover-background: $theme-primary-active,
				$hover-border: $theme-primary-active,
				$active-background: $theme-primary-active,
				$active-border: $theme-primary-active);

			@include color-inverted {
				border-color: $white;
			}
		}

		&-outline-primary {
			@include button-outline-variant($color: $theme-primary,
				$active-background: $theme-primary-active,
				$active-border: $theme-primary-active);
		}

		&-check {
			&:focus {

				+.btn-primary,
				+.btn-outline-primary {
					box-shadow: 0 0 0 $btn-focus-width rgba($theme-primary-active, 0.5);
				}
			}

			&:checked,
			&:active {

				+.btn-primary,
				+.btn-outline-primary {
					color: color-contrast($theme-primary-active);
					background-color: $theme-primary-active;
					border-color: $theme-primary-active;

					&:focus {
						@if $enable-shadows {
							@include box-shadow($btn-active-box-shadow,
								0 0 0 $btn-focus-width rgba($theme-primary-active, 0.5));
						}

						@else {
							box-shadow: none;
						}
					}
				}
			}
		}
	}

	.form {
		&-check-input:checked {
			background-color: $black;
			border-color: $black;

			@include color-inverted($nest: false) {
				background-color: $white;
				border-color: $white;
			}
		}

		&-range {

			&::-webkit-slider-thumb,
			&::-moz-range-thumb {
				background: $theme-primary;
			}

			&:active {

				&::-webkit-slider-thumb,
				&::-moz-range-thumb {
					background: $theme-primary-active;
				}
			}

			&:disabled {

				&::-webkit-slider-thumb,
				&::-moz-range-thumb {
					background: $form-range-thumb-disabled-bg;
				}
			}
		}
	}
}

/* ==========================================================================

   EASY FORM OVERWRITES

   ========================================================================== */

.portaltype-easyform {
	div[data-panel="content"] {
		.documentFirstHeading {
			display: none;
		}

		header {
			margin-bottom: $spacer;

			>* {
				@extend %typo-pagetitle;
			}

			.documentFirstHeading {
				display: block;
			}
		}

		// Layout
		#content-core {
			#form {
				@at-root #formPrologue {
					@extend %rich-text-base;
					@extend %rich-text-base-extended;
					@extend %rich-text-remove-first-margin;
					@extend %rich-text-main-column;

					&:not(:empty) {
						margin-bottom: $spacer;
					}
				}

				@at-root #formEpilogue {
					@extend %rich-text-base;
					@extend %rich-text-base-extended;
					@extend %rich-text-remove-first-margin;
					@extend %rich-text-small-column;

					&:not(:empty) {
						margin-top: $spacer * 3;
					}
				}

				@include breakpoint-desktop {
					display: grid;
					grid-template-columns: 4fr 8fr;
					grid-template-rows: auto;
					margin-left: px-to-rem(-$_grid-gutter-width-half);
					margin-right: px-to-rem(-$_grid-gutter-width-half);

					>* {
						margin-left: px-to-rem($_grid-gutter-width-half);
						margin-right: px-to-rem($_grid-gutter-width-half);
						grid-column: 2/2;
					}

					#formEpilogue {
						grid-column: 1/2;
						grid-row: 1 / span 99;

						&:not(:empty) {
							margin-top: 0;
						}
					}
				}
			}
		}

		label .required,
		label.required {
			&:after {
				padding-left: $space-px;
			}
		}

		.portalMessage {
			margin-bottom: $spacer;
			margin-left: auto;
			padding-left: 0;
			padding-right: 0;
			border-left: 0;
			border-right: 0;
			animation: none;
			border-color: $red;
			color: $black;

			svg {
				display: none;
			}

			@include breakpoint-desktop {
				width: calc(#{percentage(div(8, 12))} + #{px-to-rem(($_grid-gutter-width * div(8, 12)) - $_grid-gutter-width)});
			}

			@include breakpoint-upscale {
				width: calc(#{percentage(div(8, 12))} + #{px-to-vw(($_grid-gutter-width * div(8, 12)) - $_grid-gutter-width)});
			}
		}

		.form {

			&-control,
			&-select {
				border-left: 0;
				border-right: 0;
			}

			&-control {
				@extend %custom-form-control;

				&.form-control-empty {
					background: $listing-hover-bg;
				}

				@at-root .portaltype-easyform {
					@include color-inverted($same-selector: true) {
						div[data-panel="content"] .form-control.form-control-empty {
							background: $invert-listing-hover-bg;
						}
					}
				}
			}

			&-select {
				@extend %custom-form-select;
			}

			&-check-input {
				@extend %custom-form-check;
			}

			&Controls {
				display: flex;
				justify-content: flex-end;
				gap: $spacer;
				margin-top: $spacer;
				margin-bottom: 0;
				border-top: $border-width solid;
				border-bottom: $border-width solid;

				.btn {
					@extend %custom-form-submit;
					@extend %custom-btn;
					@extend .btn-link;
					background: none;

					.js-focus-visible &:focus.focus-visible {
						background: $black;
					}
				}
			}
		}
	}
}

/* ==========================================================================

   PLONE EDIT OVERWRITES

   ========================================================================== */

.template-edit {
	div[data-panel="content"] {
		.documentFirstHeading {
			margin-bottom: $spacer;
		}

		header {

			.documentFirstHeading,
			.documentDescription {
				display: none;
			}
		}
	}

	@include color-inverted($same-selector: true) {
		.picker__input {
			color: $black;
		}

		.mce-text {
			color: $gray-700 !important;
			animation: HERE-WE-ARE;
		}
	}
}

#babel-edit {
	@include color-inverted($same-selector: false) {

		.field,
		#frame-content .field,
		#frame-content .field #frame-content .field {
			background-color: $black;
			color: $white;
		}

		.selected,
		#frame-content .selected {
			background-color: $invert-listing-hover-bg;
			color: $white;
		}
	}
}

.template-folder_contents {
	@include color-inverted($same-selector: true) {
		.table {
			background: $white;
			color: $black;
		}

		.fc-breadcrumbs a {
			&:hover {
				color: $white;
			}
		}

		.popover,
		.form-control {
			color: $black;
		}

		.form-control:focus {
			background: transparent;
		}
	}
}

.template-actions-controlpanel {
	.list-group-item a.btn-primary {
		color: $white;
	}
}