/* ==========================================================================

   MAIN PLACEHOLDER

   ========================================================================== */

%neutralize-button {
	@include neutralize-button;
}

%ios-defocus-fix {
	// iOS fix
	.js-focus-visible &:focus {
		outline: none;
	}
}

/* ==========================================================================

   TYPOGRAPHY PLACEHOLDER

   ========================================================================== */

%typo-pagetitle {
	font-size: px-to-rem($_title-font-size-mobile);
	line-height: $title-line-height;

	@include breakpoint-desktop {
		font-size: px-to-rem($_title-font-size);
	}

	@include breakpoint-upscale {
		font-size: px-to-vw($_title-font-size);
	}
}

%typo-small {
	font-size: px-to-rem($_small-font-size);

	@include breakpoint-upscale {
		font-size: px-to-vw($_small-font-size);
	}
}

%typo-list-small {
	@extend %typo-small;
	line-height: $small-list-line-height;
}

%typo-big {
	font-size: px-to-rem($_big-font-size);

	@include breakpoint-upscale {
		font-size: px-to-vw($_big-font-size);
	}
}

/* ==========================================================================

   STRONG PLACHOLDER

   ========================================================================== */

%typo-strong {
	// iOS fix for: background-color in iOS Safari overlap
	background: linear-gradient(to right, $text-highlight 100%, transparent);
	background-position: 0 px-to-rem(1);
	background-repeat: repeat-x;

	@include color-inverted {
		background-image: linear-gradient(to right, $invert-text-highlight 100%, transparent);
	}
}

%typo-strong-print {
	@include print-only {
		position: relative;
		background: none;
		box-shadow: inset 0 2em 0 $text-highlight !important;
	}
}

/* ==========================================================================

   TOP LINED TEXT PLACEHOLDER

   ========================================================================== */

%top-lined-text {
	border-top: px-to-rem($_border-width) solid;
	padding-top: $lined-text-py;
}

%unstyle-top-lined-text {
	border-top: none;
	padding-top: 0;
}

/* ==========================================================================

   DOT MARKER PLACEHOLDER

   ========================================================================== */

%dot-marker {
	position: relative;

	&:before {
		content: "•";
		position: absolute;
		left: -$spacer * 0.5;
	}
}

/* ==========================================================================

   LINK PLACEHOLDER

   ========================================================================== */

%link-unstyled {
	&,
	&:hover,
	&:active,
	&:visited,
	&:focus {
		color: inherit;
		text-decoration: none;
	}
}

%link-unstyled-important {
	&,
	&:hover,
	&:active,
	&:visited,
	&:focus {
		color: inherit !important;
		text-decoration: none !important;
	}
}

%text-link {
	@extend %link-unstyled;
	@include link-underline($black);
	color: $black;
	display: inline; // necessary for link-underline()
	opacity: $text-link-opacity;

	&:hover {
		color: inherit;
		opacity: 1;
	}

	@include color-inverted {
		@include link-underline($white);
		color: $white;
		opacity: $invert-text-link-opacity;

		&:hover {
			color: inherit;
			opacity: 1;
		}
	}
}

%dark-link {
	@extend %link-unstyled;

	&:hover {
		color: inherit;
		opacity: $text-link-opacity;

		@include color-inverted {
			color: inherit;
			opacity: $invert-text-link-opacity;
		}

		@include menu-inverted {
			color: inherit;
			opacity: $text-link-opacity;
		}
	}
}

%nav-link {
	@extend %link-unstyled;
	@extend %dark-link;
	display: inline; // necessary for link-underline()

	&:hover {
		@include link-underline($black);

		@include color-inverted {
			@include link-underline($white);
		}

		@include menu-inverted {
			@include link-underline($black);
		}
	}
}

%more-link {
	@extend %link-unstyled;
	position: relative;
	padding-left: $spacer * 0.666;

	&:before {
		content: ">";
		position: absolute;
		top: 0;
		left: 0;
	}

	&:hover {
		color: inherit;

		span {
			color: inherit;
			opacity: $text-link-opacity;
			@include link-underline($black);

			@include color-inverted {
				color: inherit;
				opacity: $invert-text-link-opacity;
				@include link-underline($white);
			}
		}
	}
}

%enlarge-clickarea {
	position: relative;

	&:before {
		content: "";
		background: transparent;
		position: absolute;
		top: -$spacer * 0.125;
		left: -$spacer * 0.25;
		right: -$spacer * 0.25;
		bottom: -$spacer * 0.125;
	}
}

/* ==========================================================================

   LIST GROUP STYLES PLACEHOLDER

   ========================================================================== */

%list-group-content-styles {
	.dl-group {
		margin-top: $spacer * 0.5;
	}
}

/* ==========================================================================

   CAROUSEL/VIDEO WIDTH STYLES PLACEHOLDER

   ========================================================================== */

%display-desktop-width {
	@include breakpoint-desktop {
		width: calc(
			#{percentage($editor-image-width-fact)} + #{px-to-rem(
					($_grid-gutter-width * $editor-image-width-fact) - $_grid-gutter-width
				)}
		);
	}

	@include breakpoint-upscale {
		width: calc(
			#{percentage($editor-image-width-fact)} + #{px-to-vw(
					($_grid-gutter-width * $editor-image-width-fact) - $_grid-gutter-width
				)}
		);
	}
}

%display-max-width {
	max-width: 100%;

	@include breakpoint-desktop {
		max-width: calc(
			#{percentage($editor-image-width-fact)} + #{px-to-rem(
					($_grid-gutter-width * $editor-image-width-fact) - $_grid-gutter-width
				)}
		);
	}

	@include breakpoint-upscale {
		max-width: calc(
			#{percentage($editor-image-width-fact)} + #{px-to-vw(
					($_grid-gutter-width * $editor-image-width-fact) - $_grid-gutter-width
				)}
		);
	}
}

%display-desktop-width-portrait {
	@include media-breakpoint-up(sm) {
		width: calc(
			#{percentage($editor-image-width-fact-portrait)} + #{px-to-rem(
					($_grid-gutter-width * $editor-image-width-fact-portrait) - $_grid-gutter-width
				)}
		);
	}

	@include breakpoint-upscale {
		width: calc(
			#{percentage($editor-image-width-fact-portrait)} + #{px-to-vw(
					($_grid-gutter-width * $editor-image-width-fact-portrait) - $_grid-gutter-width
				)}
		);
	}
}

%display-max-width-portrait {
	max-width: 100%;

	@include media-breakpoint-up(sm) {
		max-width: calc(
			#{percentage($editor-image-width-fact-portrait)} + #{px-to-rem(
					($_grid-gutter-width * $editor-image-width-fact-portrait) - $_grid-gutter-width
				)}
		);
	}

	@include breakpoint-upscale {
		max-width: calc(
			#{percentage($editor-image-width-fact-portrait)} + #{px-to-vw(
					($_grid-gutter-width * $editor-image-width-fact-portrait) - $_grid-gutter-width
				)}
		);
	}
}

/* ==========================================================================

   CUSTOM FORM PLACEHOLDER

   ========================================================================== */

%custom-form-control {
	padding-left: 0;
	padding-right: 0;
	resize: none;

	.js-focus-visible &:focus.focus-visible {
		outline: 0 !important;
		background: transparent;
	}
}

%custom-form-select {
	padding-left: 0;
	padding-right: $form-select-padding-x * 2;
	background-position: right center;
}

%custom-form-check {
	&:checked[type="radio"],
	&:checked[type="checkbox"] {
		background-image: none;
	}
}

%custom-form-submit {
	&:after {
		content: ">";
		padding-left: $space-px;
	}
}

%custom-btn {
	border: none;
	padding: $input-padding-y 0;
}

/* ==========================================================================

   TINYMCE TEMPLATE ALERT PLACEHOLDER

   ========================================================================== */

%tinymce-template-alert {
	background: $red !important;

	&:before {
		content: "Element not allowed. Press [enter] or [backspace] to delete this element!";
		color: $white !important;
	}
}

%tinymce-template-alert-backspace {
	@extend %tinymce-template-alert;

	&:before {
		content: "Element not allowed. Press [backspace] to delete this element!";
	}
}

%tinymce-template-alert-dl {
	@extend %tinymce-template-alert;

	&:before {
		content: "Copy & paste term-definition pair (from above) to create a new item! Press [enter] or [backspace] to delete this element!";
	}
}
