.container-fluid {
    padding-left: px-to-rem($_grid-gutter-width);
    padding-right: px-to-rem($_grid-gutter-width);

    @include breakpoint-upscale {
        padding-left: px-to-vw($_grid-gutter-width);
        padding-right: px-to-vw($_grid-gutter-width);
    }

    body:not(#{$select-logged-out}) & {
        max-width: $viewport-width-base;
    }
}

.row {
    @include breakpoint-upscale {
        margin-left: px-to-vw(-$_grid-gutter-width-half);
        margin-right: px-to-vw(-$_grid-gutter-width-half);

        > * {
            padding-left: px-to-vw($_grid-gutter-width-half);
            padding-right: px-to-vw($_grid-gutter-width-half);
        }
    }
}

.content-layout {
    @at-root .area {
        // prevents wrong area widths when child elements use `white-space:nowrap`
        min-width: 0;

        &-head,
        &-above {
            @include m-node-not-empty {
                margin-bottom: $spacer;
            }
        }

        &-content + &-below {
            @include m-node-not-empty {
                margin-top: $spacer * 3;

                @include breakpoint-desktop {
                    margin-top: 0;
                }
            }
        }

        &-extra-right {
            @include m-node-not-empty {
                margin-top: $spacer * 4;
            }
        }

        &-extra-left {
            @include m-node-not-empty {
                margin-top: $spacer * 4;

                + .area-extra-right {
                    @include m-node-not-empty {
                        margin-top: $spacer;

                        @include breakpoint-desktop {
                            margin-top: $spacer * 4;
                        }
                    }
                }
            }
        }

        // Startpage
        &-main + &-side {
            @include m-node-not-empty {
                margin-top: $spacer * 2;

                @include breakpoint-desktop {
                    margin-top: 0;
                }
            }
        }
    }

    @include breakpoint-desktop {
        display: grid;
        grid-template-columns: 4fr 8fr;
        grid-template-rows: auto auto 1fr;
        grid-template-areas:
            "head head"
            "above content"
            "below content"
            "extra-left extra-right";
        margin-left: px-to-rem(-$_grid-gutter-width-half);
        margin-right: px-to-rem(-$_grid-gutter-width-half);

        // Startpage
        &-home {
            grid-template-columns: 8fr 4fr;
            grid-template-rows: auto;
            grid-template-areas: "main side";
        }

        @at-root .area {
            padding-left: px-to-rem($_grid-gutter-width-half);
            padding-right: px-to-rem($_grid-gutter-width-half);

            &-head {
                grid-area: head;
            }

            &-above {
                grid-area: above;

                @include m-node-not-empty {
                    margin-bottom: $spacer * 3;
                }
            }

            &-content {
                grid-area: content;
            }

            &-below {
                grid-area: below;
            }

            &-extra-left {
                grid-area: extra-left;
            }

            &-extra-right {
                grid-area: extra-right;
            }

            // Startpage
            &-main {
                grid-area: main;
            }

            &-side {
                grid-area: side;
            }
        }
    }

    @include breakpoint-upscale {
        margin-left: px-to-vw(-$_grid-gutter-width-half);
        margin-right: px-to-vw(-$_grid-gutter-width-half);

        @at-root .area {
            padding-left: px-to-vw($_grid-gutter-width-half);
            padding-right: px-to-vw($_grid-gutter-width-half);
        }
    }
}

.col-list-date {
    width: px-to-rem($_col-list-date-w);
    max-width: percentage(div($_col-list-date-w, 375));

    @include breakpoint-upscale {
        width: px-to-vw($_col-list-date-w);
    }
}

.col-list-event-info {
    width: $col-list-event-info-w;
}

.col-list-image {
    width: $col-list-image-w;
}

.col-list-course {
    width: px-to-rem($_col-list-course-w);
    max-width: percentage(div($_col-list-course-w, 375));

    @include breakpoint-upscale {
        width: px-to-vw($_col-list-course-w);
    }
}
