// NOTE: Variables starting with `$_` are unitless for better calculation

// TODO: [2][dev] Suche



/* ==========================================================================

   MAIN VARIABLES

   ========================================================================== */

// Selector
$select-menu: ".offcanvas";
$select-logged-out: ".userrole-anonymous";
$select-plone-modal: ".modal, .modal-content";
$select-mosaic-listing-tile: ".mosaic-akbild\\.tiles\\.listing-tile";
$select-mosaic-lectures-tile: ".mosaic-employeedetails-lectures-tile";
$mosaic-collectionfilter-base: "mosaic-collective\\.collectionfilter\\.tiles\\.";
$select-mosaic-sorting-tile: ".#{$mosaic-collectionfilter-base}sortOn-tile";
$select-collectionfilter: '[class*="#{$mosaic-collectionfilter-base}"]';
// $select-mosaic-filter-tile: ".#{$mosaic-collectionfilter-base}filter-tile";
// $select-mosaic-search-tile: ".#{$mosaic-collectionfilter-base}search-tile";

// Selectors are assigned by JS
$select-mosaic-nodes-empty: ".akbild-empty";
$select-mosaic-first-node-in-area: ".akbild-firstchild";
$select-mosaic-last-node-in-cell: ".akbild-lastchild";

// Typography
$word-spacing-base: -0.07em;
$_copy-font-size: 19;
$copy-line-height: 1.2;
$_logo-font-size: 75;
$_logo-font-size-mobile: 32;
$logo-line-height: 1;
$logo-letter-spacing: letter-spacing-to-em(-15);
$_title-font-size: 50;
$_title-font-size-mobile: $_logo-font-size-mobile;
$title-line-height: $copy-line-height;
$_small-font-size: 15;
$small-list-line-height: 1.35;
$_big-font-size: 29;

// space space
$space-px: em(0.25);

// small factor
$small-font-factor: div($_copy-font-size, $_small-font-size);

// Function variables
$em-font-size-base: $_copy-font-size;
$viewport-width-base: 1600px;

// Lined text
$_lined-text-py: 5;
$lined-text-py: px-to-em($_lined-text-py);

// Grid
$_grid-gutter-width: 16;
$_grid-gutter-width-half: $_grid-gutter-width * 0.5;

// Content padding … `em($copy-line-height)` == `$spacer`
$content-wrapper-pt: em($copy-line-height) * 1.5;
$content-wrapper-pb: em($copy-line-height) * 4;

// List columns
$_col-list-date-w: 128;
$col-list-event-info-w: percentage(div(1, 5));
$col-list-image-w: percentage(div(2, 13));
$_col-list-course-w: 106;

// Border
$_border-width: 1;

// Focus outline
$_outline-width: 3;

// Text link opacity
$text-link-opacity: 66.5%;
$invert-text-link-opacity: 46%;

// Carousel custom
$carousel-custom-footer-pt: em(0.333);
$carousel-custom-caption-pl: em(1);
$carousel-custom-controls-pr: em(0.5);

// Date and time spacings
$date-dash-px: 0.14em;
$time-h-pl: 0.14em;
$time-dash-px: 0.14em;

// Easing
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);

// Collapse dropdown
$_collapse-dropdown-icon-w: 14;
$_collapse-dropdown-icon-h: 12;
$collapse-dropdown-icon-transition: none;

// Select icon
$form-select-bg-icon-w: px-to-em(10);
$form-select-bg-icon-h: px-to-em(16);

// Sidebar logos
$sidebar-logo-w: px-to-rem(170);

// Editor image width
$editor-image-width-fact: div(6, 8);
$editor-image-width-fact-portrait: div(3, 8);

// Z indexes
$z-minus: -1;

/* ==========================================================================

   PLONE VARIABLES

   ========================================================================== */

// Exclude Roboto as webfont
$enable-roboto-webfont: false;

/* ==========================================================================

   BOOTSTRAP GRAYSCALE VARIABLES

   ========================================================================== */

// bs grayscale color system
$white: #ffffff;
$gray-100: #f8f8f8; // used: menu bg
$gray-200: #d5d5d5; // used: Single Unit Exhibits, $light
$gray-300: #b9b9b9; // used: print
$gray-400: #a2a2a2;
$gray-500: #8b8b8b; // used: dropdown
$gray-600: #767676; // accessible gray, used a lot
$gray-700: #5d5d5d;
$gray-800: #333333; // btn-primary
$gray-900: #222222; // used: $dark
$black: #000000;

// Body
$body-bg: $white;
$body-color: $black;

/* ==========================================================================

   AKBILD COLOR VARIABLES

   ========================================================================== */

$invert-body-color: $white;

// Color system
$akbild-springgreen: #9ae15c;
$akbild-flamingo: #ffd2da;
$akbild-orange: #ff700a;
$akbild-cream: #ffeac2;
$akbild-lavender: #c6bfea;
$akbild-aquablue: #32b8de;
$akbild-neongelb: #f4ff2d;

// Multiple units (keep lowercase)
$mu-colors: (
	"ibk": $akbild-springgreen,
	"ika": $akbild-flamingo,
	"ikl": $akbild-orange,
	"ikr": $akbild-cream,
	"ikw": $akbild-lavender,
	"intk": $akbild-aquablue,
);

// Single units (keep lowercase)
$su-colors: (
	"academy": $white,
	"studentservice": $akbild-neongelb,
	"paintingsgallery": $black,
	"glyptothek": $black,
	"graphiccollection": $black,
	"archive": $white,
	"library": $white,
	"exhibiteschenbachgasse": $gray-200,
	"exhibitgalerie": $gray-200,
	"exhibitforum": $gray-200,
	"exhibitstudio": $gray-200,
	// Old, but necessary tags
	"xhibit": $gray-200,
	"xposit": $gray-200,
	"xperiment": $gray-200,
	"other": $white,
);

$invert-red: #f14152;

// Theme primary
$theme-primary: $black;
$theme-primary-active: $gray-800;

// Text highlight color
$text-highlight: rgba(0, 0, 0, 0.15);
$invert-text-highlight: rgba(255, 255, 255, 0.2);

// Listings
$listing-hover-bg: rgba(0, 0, 0, 0.1);
$invert-listing-hover-bg: rgba(255, 255, 255, 0.133333333);

// Mark styles inverted
$invert-mark-bg: $invert-text-highlight;

// Breadcrumb
$invert-breadcrumb-divider-color: $white;
$invert-breadcrumb-active-color: $white;

// Table
$table-striped-bg: $listing-hover-bg;
$table-hover-bg: $listing-hover-bg;
$table-active-bg: $listing-hover-bg;

// Form check width
$_form-check-input-width: 12;

// Selection
$selection-background-color: $black;
$invert-selection-background-color: $white;
$selection-color: $white;
$invert-selection-color: $black;

/* ==========================================================================

   BOOTSTRAP VARIABLES

   ========================================================================== */

// Colors
$purple: $akbild-lavender;
$pink: $akbild-flamingo;
$red: #E81127;
$orange: $akbild-orange;
$yellow: $akbild-neongelb;
$green: $akbild-springgreen;
$cyan: $akbild-aquablue;
// Theme colors
$light: $gray-200;

// Options
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-grid-classes: true;
$enable-caret: true;
$enable-button-pointers: true;
$enable-rfs: false; // important
$enable-validation-icons: false;
$enable-negative-margins: false;
$enable-deprecation-messages: true;
$enable-important-utilities: true;
$enable-smooth-scroll: true;
$enable-cssgrid: false;

// Typography
$font-family-sans-serif: Arial, sans-serif;
$font-family-condensed: "Arial Narrow", sans-serif; //just on toolbar
$font-family-code: "Courier New", monospace;
$font-family-serif: "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$font-family-code: $font-family-code;
$font-size-base: px-to-rem($_copy-font-size);
$font-size-sm: $font-size-base;
$font-size-lg: $font-size-base;
$line-height-base: $copy-line-height;
$font-weight-base: normal;
$font-weight-bold: $font-weight-base;
$font-weight-bolder: $font-weight-base;
$h1-font-size: inherit;
$h2-font-size: inherit;
$h3-font-size: inherit;
$h4-font-size: inherit;
$h5-font-size: inherit;
$h6-font-size: inherit;
$headings-line-height: $title-line-height;
$headings-font-weight: inherit;
$headings-margin-bottom: 0;
$display-font-sizes: (
	1: px-to-rem($_title-font-size),
);
$display-font-weight: $font-weight-base;
$display-line-height: $title-line-height;
$lead-font-size: $font-size-base;
$lead-font-weight: $font-weight-base;
$small-font-size: px-to-em($_small-font-size);

// Links
$link-color: $gray-600;
$link-hover-color: $black;

// Margins
$hr-margin-y: 0;
$paragraph-margin-bottom: 0;

// Legend
$legend-font-size: inherit;
$legend-font-weight: inherit;
$legend-margin-bottom: 0;

// Blockquote
$blockquote-font-size: inherit;
$blockquote-footer-color: inherit;
$blockquote-footer-font-size: inherit;
$blockquote-margin-y: 0;

// Code
$code-color: $black;

// HR
$hr-opacity: 100%;

// Border
$border-width: px-to-rem($_border-width);
$border-color: inherit;

// Mark styles
$mark-bg: $text-highlight;
$mark-padding: 0;

// Spacing unit overwrites — prevents compiler error
$modal-inner-padding: px-to-rem($_copy-font-size * $copy-line-height);

// Spacing
$spacer: em($copy-line-height);

// Grid
$grid-columns: 12;
$grid-gutter-width: px-to-rem($_grid-gutter-width);

// Grid breakpoints
$grid-breakpoints: (
	xs: 0,
	// headernav, menu
	// NOTE: Sync with _filter-expand.js!
	sm: px-to-rem(576px),
	// layout switch
	md: px-to-rem(776px),
	// menu
	lg: px-to-rem(1088px),
);

// Container padding
$container-padding-x: $grid-gutter-width;

// Breadcrumbs
$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: $spacer * 0.25;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $black;
$breadcrumb-active-color: $black;
$breadcrumb-divider: quote(">");

// Tables
$table-cell-padding-y: $lined-text-py;
$table-cell-padding-x: 0;
$table-cell-padding-y-sm: $table-cell-padding-y;
$table-cell-padding-x-sm: $table-cell-padding-x;
$table-variants: (
);
$table-border-factor: 0;

// Carousel
$carousel-control-color: $black;
$carousel-control-width: auto;
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 1;
$carousel-control-transition: none;
$carousel-caption-color: $carousel-control-color;
$carousel-caption-padding-y: 0;
$carousel-caption-spacer: 0;
$carousel-transition-duration: 0.666s;
$carousel-transition: transform $carousel-transition-duration ease-out;

// Figures
$figure-caption-color: inherit;

// Aspect ratio
$aspect-ratios: (
	"3x2": percentage(div(2, 3)),
	"16x9": percentage(div(9, 16)),
);

// Offcanvas
$offcanvas-padding-y: 0;
$offcanvas-padding-x: 0;
$offcanvas-horizontal-width: 100%;
// NOTE: sync with _search-overlay.js
$offcanvas-transition-duration: 0.333s;
$offcanvas-border-color: transparent;
$offcanvas-border-width: 0;
$offcanvas-bg-color: $gray-100;
$offcanvas-color: inherit;
$offcanvas-box-shadow: none;
$offcanvas-backdrop-bg: transparent;
$offcanvas-backdrop-opacity: 1;

// Close
$btn-close-width: px-to-em(14);
$btn-close-padding-x: 0;
$btn-close-padding-y: 0;
$btn-close-color: $black;
$btn-close-focus-shadow: none;
$btn-close-opacity: 100%;
$btn-close-hover-opacity: $text-link-opacity;
$btn-close-focus-opacity: 100%;
$btn-close-white-filter: none;


// Collapse
$transition-collapse: height 0.333s $ease-out;
$transition-collapse-width: width 0.333s $ease-out;


/* ==========================================================================

   BOOTSTRAP   F O R M   VARIABLES

   ========================================================================== */

// Buttons + Forms
$input-btn-padding-y: $lined-text-py;
$input-btn-padding-x: $spacer * 0.5;
$input-btn-focus-width: 0;
$input-btn-padding-y-sm: $input-btn-padding-y;
$input-btn-padding-x-sm: $input-btn-padding-x;
$input-btn-padding-y-lg: $input-btn-padding-y;
$input-btn-padding-x-lg: $input-btn-padding-x;

// Buttons
$btn-box-shadow: none;
$btn-disabled-opacity: 64.5%;
$btn-active-box-shadow: $btn-box-shadow;
$btn-link-color: inherit;
$btn-link-hover-color: $btn-link-color;
$btn-link-disabled-color: $gray-600;
$btn-transition: none;
$btn-active-bg-shade-amount: 0%;
$btn-active-bg-tint-amount: 0%;
$btn-active-border-shade-amount: 0%;
$btn-active-border-tint-amount: 0%;

// Placeholders
$input-placeholder-color: inherit;
$placeholder-opacity-max: $text-link-opacity;
$placeholder-opacity-min: $placeholder-opacity-max;

// Forms
$form-text-margin-top: $spacer * 0.25;
$form-text-color: $body-color;
$form-label-margin-bottom: $spacer * 0.25;
$input-bg: transparent;
$input-disabled-bg: $listing-hover-bg;
$input-border-color: $black;
$input-box-shadow: none;
$input-focus-bg: $white;
$input-focus-border-color: inherit;
$input-transition: none;
$form-check-input-width: px-to-em($_form-check-input-width);
$form-check-min-height: $spacer;
$form-check-padding-start: $form-check-input-width + $spacer * 0.4;
$form-check-margin-bottom: 0;
$form-check-input-active-filter: none;
$form-check-input-border: $border-width solid $input-border-color;
// $form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-disabled-opacity: $btn-disabled-opacity;
$form-check-inline-margin-end: $spacer;
$input-group-addon-bg: transparent;
$form-select-indicator-padding: $input-btn-padding-x * 2 + $form-select-bg-icon-w;
$form-select-disabled-bg: $input-disabled-bg;
$form-select-bg-size: $form-select-bg-icon-w $form-select-bg-icon-h;
$form-select-indicator: none;
$form-range-track-height: px-to-em(1);
$form-range-track-bg: $black;
$form-range-track-border-radius: 0;
$form-range-track-box-shadow: none;
$form-range-thumb-width: $form-check-input-width;
// $form-range-thumb-bg: $component-active-bg !default;
$form-range-thumb-border-radius: 0;
$form-range-thumb-box-shadow: none;
// $form-range-thumb-active-bg: tint-color($component-active-bg, 70%) !default;
$form-range-thumb-disabled-bg: $gray-600;
$form-range-thumb-transition: none;

// Form validation
$form-feedback-valid-color: $black;
$form-feedback-invalid-color: $red;

// Alerts
$alert-margin-bottom: 0;
$alert-border-radius: 0;
$alert-link-font-weight: $font-weight-base;
$alert-border-width: $border-width;

// Tooltips
$tooltip-font-size: $small-font-size;
$tooltip-opacity: 1;

// Modals
$modal-content-border-width: 1px;
$modal-backdrop-opacity: 15%;

// Popovers
$popover-font-size: $small-font-size;
$popover-border-color: $black;
$popover-body-padding-y: $spacer * 0.25;
$popover-body-padding-x: $spacer * 0.5;

// Pagination
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-padding-y-sm: $pagination-padding-y;
$pagination-padding-x-sm: $pagination-padding-x;
$pagination-padding-y-lg: $pagination-padding-y;
$pagination-padding-x-lg: $pagination-padding-x;
$pagination-color: inherit;
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-margin-start: 0;
$pagination-focus-color: inherit;
$pagination-focus-bg: $pagination-bg;
$pagination-hover-color: inherit;
$pagination-hover-bg: $pagination-bg;
$pagination-active-color: $pagination-color;
$pagination-active-bg: $pagination-bg;
$pagination-disabled-bg: $pagination-bg;
$pagination-transition: none;

// List group
$list-group-color: inherit;
$list-group-bg: transparent;
$list-group-border-width: 0;
$list-group-item-padding-y: $lined-text-py;
$list-group-item-padding-x: 0;
$list-group-item-bg-scale: 0%;
$list-group-item-color-scale: 0%;
$list-group-hover-bg: $listing-hover-bg;
$list-group-active-color: $list-group-color;
$list-group-active-bg: $list-group-bg;
$list-group-active-border-color: inherit;
$list-group-disabled-color: $gray-600;
$list-group-action-color: $list-group-color;
$list-group-action-hover-color: $list-group-color;
$list-group-action-active-color: $list-group-color;
$list-group-action-active-bg: $list-group-bg;

// Accordion
$accordion-padding-y: $lined-text-py;
$accordion-padding-x: 0;
$accordion-color: inherit;
$accordion-bg: transparent;
$accordion-border-width: 0;
$accordion-transition: none;
$accordion-button-color: $accordion-color;
$accordion-button-active-bg: $accordion-bg;
$accordion-button-active-color: $accordion-color;
$accordion-button-focus-box-shadow: none;
$accordion-icon-width: px-to-em($_collapse-dropdown-icon-w);
$accordion-icon-transition: none;
$accordion-icon-transform: rotate(-180deg);
$accordion-button-icon: none;
$accordion-button-active-icon: none;