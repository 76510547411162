/* ==========================================================================

   MAIN MIXINS

   ========================================================================== */

@mixin neutralize-button {
	border: 0;
	border-radius: 0;
	background: transparent;
	text-transform: inherit;
	padding: 0;
	text-align: left;
}

@mixin scrollable {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

@mixin gray-multiply {
	filter: grayscale(1);
	mix-blend-mode: multiply;
}

/* ==========================================================================

   MOSAIC NODES MIXINS

   ========================================================================== */

@mixin m-node-not-empty {
	&:not(#{$select-mosaic-nodes-empty}) {
		@content;
	}
}

/* ==========================================================================

   INVERTED MENU

   ========================================================================== */

@mixin menu-inverted($only-screen: true) {
	#{$select-menu} & {
		@include color-inverted($only-screen: $only-screen, $nest: false) {
			@content;
		}
	}
}

/* ==========================================================================

   INVERTED PLONE MODAL

   ========================================================================== */

@mixin plone-modal-inverted($only-screen: true) {
	#{$select-plone-modal} & {
		@include color-inverted($only-screen: $only-screen, $nest: false) {
			@content;
		}
	}
}

/* ==========================================================================

   LINK UNDERLINE

   ========================================================================== */

@mixin link-underline($color: $black) {
	box-shadow: inset 0 px-to-rem(-$_border-width) $color;

	@include print-only {
		text-decoration: underline;
	}
}

@mixin link-underline-important($color: $black) {
	box-shadow: inset 0 px-to-rem(-$_border-width) $color !important;

	@include print-only {
		text-decoration: underline !important;
	}
}

@mixin remove-link-underline {
	box-shadow: none;

	@include color-inverted {
		box-shadow: none;
	}

	@include menu-inverted {
		box-shadow: none;
	}

	@include print-only {
		text-decoration: none;
	}
}

@mixin remove-link-underline-important {
	box-shadow: none !important;

	@include color-inverted {
		box-shadow: none !important;
	}

	@include menu-inverted {
		box-shadow: none !important;
	}

	@include print-only {
		text-decoration: none !important;
	}
}

/* ==========================================================================

   WRAPPER

   ========================================================================== */

@mixin page-wrapper($at-root: false) {
	@if $at-root {
		@at-root .outer-wrapper {
			@content;
		}
	} @else if & {
		.outer-wrapper & {
			@content;
		}
	} @else {
		.outer-wrapper {
			@content;
		}
	}
}

@mixin content-wrapper {
	.content-layout {
		@content;
	}
}

@mixin startpage {
	body.section-home & {
		@content;
	}
}

@mixin not-startpage {
	body:not(.section-home) & {
		@content;
	}
}

@mixin not-in-listing-or-filter-tile {
	.mosaic-tile:not(#{$select-mosaic-listing-tile}):not(#{$select-collectionfilter}) {
		@content;
	}
}

@mixin not-in-startpage {
	.content-layout-dynamic & {
		@content;
	}
}

@mixin listing-after-sorting-tile {
	#{$select-mosaic-sorting-tile} + #{$select-mosaic-listing-tile} {
		@content;
	}
}

@mixin not-startpage-carousel {
	&:not(.carousel-custom-listing) {
		@content;
	}
}

/* ==========================================================================

   MEDIA MIXINS

   ========================================================================== */

@mixin print-only {
	@media only print {
		@content;
	}
}

@mixin screen-only {
	@media only screen {
		@content;
	}
}

@mixin breakpoint-upscale {
	@media only screen and (min-width: px-to-rem($viewport-width-base)) {
		@if str-index(#{&}, 'body') {
			@at-root #{selector-replace(&, 'body', 'body#{$select-logged-out}')} {
				@content;
			}
		} @else {
			#{$select-logged-out} & {
				@content;
			}
		}
	}
}

@mixin breakpoint-menu2col {
	@include screen-only {
		@include media-breakpoint-up(sm) {
			@content;
		}
	}
}

@mixin breakpoint-sorting {
	@include screen-only {
		@include media-breakpoint-up(sm) {
			@content;
		}
	}
}

@mixin breakpoint-desktop {
	@include screen-only {
		@include media-breakpoint-up(md) {
			@content;
		}
	}
}

@mixin dark-mode {
	@media screen and (prefers-color-scheme: dark) {
		@content;
	}
}
