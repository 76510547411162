@mixin icon-base {
	background: transparent;
	background-repeat: no-repeat;
	background-size: contain;
}

/* ==========================================================================

   CLOSE

   ========================================================================== */

@mixin icon-close($color: $black) {
	@include icon-base;
	background-image: url(escape-svg(
		'data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L13 1" stroke="#{$color}" stroke-width="1.5"/><path d="M13 13L7 7L1 13" stroke="#{$color}" stroke-width="1.5"/></svg>'
	));
}

%icon-close-black {
	@include icon-close;
}

%icon-close-white {
	@include icon-close($color: $white);
}

%icon-close {
	@extend %icon-close-black;

	.js-focus-visible &:focus.focus-visible {
		@extend %icon-close-white;
	}

	@include color-inverted($only-screen: false, $nest: false) {
		@extend %icon-close-white;

		.js-focus-visible &:focus.focus-visible {
			@extend %icon-close-black;
		}
	}

	@include menu-inverted($only-screen: false) {
		@extend %icon-close-black;

		.js-focus-visible &:focus.focus-visible {
			@extend %icon-close-white;
		}
	}
}

/* ==========================================================================

   DROPDOWN ARROW

   ========================================================================== */

@mixin icon-arrow-down($color: $black) {
	@include icon-base;
	background-image: url(escape-svg(
		'data:image/svg+xml,<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4L7 10L13 4" stroke="#{$color}" stroke-width="1.5"/></svg>'
	));
}

%icon-arrow-down-black {
	@include icon-arrow-down;
}

%icon-arrow-down-white {
	@include icon-arrow-down($color: $white);
}

%icon-arrow-down {
	@extend %icon-arrow-down-black;

	.js-focus-visible &:focus.focus-visible {
		@extend %icon-arrow-down-white;
	}

	@include color-inverted($only-screen: false, $nest: false) {
		@extend %icon-arrow-down-white;

		.js-focus-visible &:focus.focus-visible {
			@extend %icon-arrow-down-black;
		}
	}

	@include menu-inverted($only-screen: false) {
		@extend %icon-arrow-down-black;

		.js-focus-visible &:focus.focus-visible {
			@extend %icon-arrow-down-white;
		}
	}
}

/* ==========================================================================

   SELECT INDICATOR

   ========================================================================== */

@mixin icon-select-indicator($color: $black) {
	background-image: url(escape-svg(
		'data:image/svg+xml,<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L5 2L1 6" stroke="#{$color}" stroke-width="1.5"/><path d="M1 10L5 14L9 10" stroke="#{$color}" stroke-width="1.5"/></svg>'
	));
}

%icon-select-indicator-black {
	@include icon-select-indicator;
}

%icon-select-indicator-white {
	@include icon-select-indicator($color: $white);
}

%icon-select-indicator {
	@extend %icon-select-indicator-black;

	@include color-inverted($only-screen: false, $nest: false) {
		@extend %icon-select-indicator-white;
	}

	@include plone-modal-inverted {
		@include icon-select-indicator;
	}
}

/* ==========================================================================

   SORTING ARROW

   ========================================================================== */

@mixin icon-sorting-arrow($color: $black) {
	@include icon-base;
	background-image: url(escape-svg(
		'data:image/svg+xml,<svg width="12" height="11" viewBox="0 0 12 11" fill="none" stroke="#{$color}" stroke-width="1.3" xmlns="http://www.w3.org/2000/svg"><path d="M11 6L6 1L1 6"/><path d="M6 1L6 11"/></svg>'
	));
}

%icon-sorting-arrow-black {
	@include icon-sorting-arrow;
}

%icon-sorting-arrow-white {
	@include icon-sorting-arrow($color: $white);
}

%icon-sorting-arrow {
	@extend %icon-sorting-arrow-black;

	.js-focus-visible &:focus.focus-visible {
		@extend %icon-sorting-arrow-white;
	}

	@include color-inverted($only-screen: false, $nest: false) {
		@extend %icon-sorting-arrow-white;

		.js-focus-visible &:focus.focus-visible {
			@extend %icon-sorting-arrow-black;
		}
	}
}
