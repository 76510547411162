%rich-text-remove-first-margin {
	> *:first-child {
		margin-top: 0 !important; // NOTE: `!important` because of `mosaic-styles.css`
	}
}

%rich-text-base {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ul,
	ol,
	figure,
	dl,
	.form-custom,
	.dl-group,
	.blockquote {
		margin-top: $spacer;
	}

	h1,
	h2 {
		margin-top: $spacer * 2;
	}

	a:not(.more-link):not(.carousel-custom-link) {
		@extend %text-link;
	}

	h3,
	h4,
	h5,
	h6 {
		+ ul,
		+ ol {
			margin-top: 0;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		+ .linklist {
			margin-top: 0;
		}
	}

	.dl-group dl {
		margin-top: 0;
	}

	li,
	dt,
	dd,
	th,
	td,
	figure,
	.blockquote {
		@extend %rich-text-remove-first-margin;
	}

	.image-richtext-figure + * {
		margin-top: $spacer * 1.5;
	}
	b,
	strong {
		@extend %typo-strong;
		@extend %typo-strong-print;
	}

	u,
	ins {
		text-decoration: none;
		font-style: italic;
	}
}

%rich-text-base-extended {
	table {
		overflow-wrap: normal;
		word-break: normal;
	}

	table,
	.table-responsive {
		margin-top: $spacer * 1.5 - px-to-em($_lined-text-py + $_border-width);
		margin-bottom: $spacer * 0.5;

		+ * {
			margin-top: $spacer * 1.5;
		}
	}
	.table-responsive {
		table {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.video {
		@extend %display-desktop-width;
		margin-top: $spacer * 1.5 !important;
		margin-bottom: $spacer * 0.5;

		+ * {
			margin-top: $spacer * 1.5 !important;
		}
	}

	video {
		@extend %display-max-width;
		display: block;
		height: auto;
		margin-top: $spacer * 1.5;
		margin-bottom: $spacer * 0.5;

		+ * {
			margin-top: $spacer * 1.5;
		}

		&.video-richtext-portrait {
			@extend %display-max-width-portrait;
		}
	}
	p video {
		margin-bottom: $spacer * 1.5;
	}

	img.image-richtext {
		margin-top: $spacer * 1.5;
		margin-bottom: $spacer * 1.5;
		margin-left: 0;
		margin-right: 0;
	}
	p,
	figure,
	a {
		img.image-richtext {
			margin-top: 0;
			margin-bottom: $spacer * 0.25;
		}
	}
	.image-richtext-link {
		opacity: 1 !important;
		box-shadow: none;
	}
	.image-richtext-figure {
		margin-top: $spacer * 1.5 * $small-font-factor !important;

		+ * {
			margin-top: $spacer * 1.5;
		}
	}
}

%rich-text-mosaic-tiles {
	.carousel {
		margin-top: $spacer * 1.5;
		margin-bottom: $spacer * 0.5;

		@include not-startpage-carousel {
			@extend %display-desktop-width;
		}
	}
	// Startpage
	.carousel.carousel-custom-listing {
		// margin-top: $lined-text-py;
		margin-top: 0;
	}

	.akbild-sorting {
		@include breakpoint-sorting {
			padding-left: 50%;
		}
	}

	@include listing-after-sorting-tile {
		.akbild-listing {
			margin-top: -$spacer;

			&:not(.js-width-adjust) {
				margin-top: $spacer * 0.25;
			}

			@include breakpoint-sorting {
				&:not(.js-width-adjust) &-header {
					width: 50%;
				}
			}
		}
	}

	.akbild-listing {
		@include not-in-startpage {
			margin-top: $spacer * 4;
			margin-bottom: $spacer * 4;
		}
	}

	#{$select-mosaic-first-node-in-area} .akbild-listing {
		@include not-in-startpage {
			margin-top: -($spacer + $lined-text-py) !important;
		}
	}

	#{$select-mosaic-lectures-tile} #{$select-mosaic-first-node-in-area} .akbild-listing {
		@include not-in-startpage {
			margin-top: 0 !important;
		}
	}

	#{$select-mosaic-lectures-tile} .mosaic-tile-content + .mosaic-tile-content {
		margin-top: $spacer * 4;
	}

	#{$select-mosaic-last-node-in-cell} .akbild-listing {
		margin-bottom: 0;
	}

	#{$select-collectionfilter} {
		.accordion {
			margin-top: $spacer;
		}
	}

	#{$select-collectionfilter} #{$select-mosaic-first-node-in-area},
	#{$select-collectionfilter} + #{$select-collectionfilter} {
		.accordion {
			margin-top: 0;
		}
	}

	#{$select-collectionfilter} + #{$select-collectionfilter} {
		.accordion-item:first-child {
			border-top: none;
		}
	}
}

%rich-text-main-column {
	$top-lined-text-mt: $spacer * 2 - px-to-em($_lined-text-py + $_border-width);

	h1,
	h2 {
		@extend %top-lined-text;

		&:not(:first-child) {
			margin-top: $top-lined-text-mt !important; // NOTE: `!important` because of `mosaic-styles.css`
		}

		+ .linklist {
			@extend %unstyle-top-lined-text;
			margin-top: $spacer;
		}
	}

	h3,
	h4,
	h5,
	h6 {
		+ .linklist {
			margin-top: $lined-text-py;
		}
	}

	.linklist {
		@extend %top-lined-text;
		margin-top: $top-lined-text-mt;
	}

	#{$select-mosaic-first-node-in-area} {
		h1:first-child,
		h2:first-child,
		.linklist:first-child {
			@extend %unstyle-top-lined-text;
		}
	}
}

%rich-text-small-column {
	> * {
		@extend %typo-small;
	}
}
