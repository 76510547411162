/* ==========================================================================

   LINKS

   ========================================================================== */

// NOTE: `.nav-link` defined in `_overwrites`

.text-link {
	@extend %text-link;
}

.dark-link {
	@extend %dark-link;
}

.more-link {
	@extend %more-link;
}

/* ==========================================================================

   DATE/TIME SPACINGS

   ========================================================================== */

.date {
	&-dash {
		padding-left: $date-dash-px;
		padding-right: $date-dash-px;
	}

	&-year,
	&-end {
		display: inline-block;
	}
}

.time {
	&-h {
		padding-left: $time-h-pl;
	}

	&-dash {
		padding-left: $time-dash-px;
		padding-right: $time-dash-px;
	}

	&-end {
		display: inline-block;
	}
}
